<template lang="pug">
#fenqi
    .fenqibox
        .buy1-box
            .imgbox(
                :style="{ backgroundImage: 'url(' + detail.thum_image_url + ')' }"
            )
            .buy1-ibox 
                p.ibox-p {{ detail.title }}
                p.ibox-s
                    strong.num {{ detail.guide_price }}
                    //span.wan 万起
        .fen-plan(v-if="staging_data.length")
            h2.fen-title 分期方案

            .buy2-box
                .plan-box
                    .plan(
                        v-for="(item, index) in staging_data",
                        :class="staging_index == index ? 'active' : ''",
                        @click="stagingIndex(index, item.id)"
                    )
                        .name {{ item.title }}

            .buy2-money
                .monny-s
                    span 首付
                    span {{ staging_data.length && staging_data[staging_index].down_pay }}元
                .monny-s
                    span 期数
                    span {{ staging_data.length && staging_data[staging_index].periods }}期
                .monny-s
                    span 月供
                    span {{ staging_data.length && staging_data[staging_index].month_pay }}元
                .monny-s
                    span 尾款
                    span {{ staging_data.length && staging_data[staging_index].final_pay }}元
        .fen-plan
            .buy2-box
                h2.title 选包装
            .buy2-money(
                @click="check_install(item)",
                v-for="item in install_data"
            )
                .monny-s.quan-l.quan(
                    :class="item.checked == true ? 'active' : ''"
                )
                    span {{ item.title }}
                    span {{ item.price }}元
        .submit(@click="next") 下一步

    //填写信息
    .buy-box(v-if="show")
        .close(@click="close()")
        .buy-i
            .imgbox(
                :style="{ backgroundImage: 'url(' + detail.thum_image_url + ')' }"
            )
            .buy-ibox 
                p.ibox-p {{ detail.title }}
                p.ibox-s
                    strong.num {{ detail.guide_price }}
                    //span.wan 万起
        form.form-box
            .form-box-item
                label 姓名
                .form-box-in
                    input(
                        type="text",
                        :placeholder="placeholder.contact",
                        max-legth="10",
                        v-model="form.contact"
                    )
            .form-box-item
                label 手机号
                .form-box-in
                    input(
                        type="text",
                        :placeholder="placeholder.contact_phone",
                        max-legth="11",
                        v-model="form.contact_phone"
                    )
            .form-box-item(@click="mendian()")
                label 提车门店
                .form-box-in
                    input.righticon(
                        type="text",
                        :placeholder="placeholder.store_id",
                        max-legth="11",
                        v-model="store_title"
                    )
                //- span.in3 请选择门店 
        //.submit 提交
        .submit(@click="submitForm", :class="selectId ? 'blue' : ''") 确认
        //.submit.blue 提交
    //门店
    .mendian-box(v-if="show1")
        h2.title 选择提车门店
        .mendian-list
            .mendian-4s(@click="check_store(item)", v-for="item in store_list")
                h3.ss(:class="item.id == selectId ? 'active' : ''") {{ item.title }}
                p.address {{ item.address }}
        .submit(@click="check_show1", :class="selectId ? 'blue' : ''") 确认
        //.submit.blue 确认    
    .shade(v-if="show2")
</template>




 

<script>
import { Radio, Tab, Tabs } from "vant";

import { httpDetail, orderSave } from "../../config/request";

import ShareImpl from '@/weixinApi.js';


export default {
    props: {},
    data() {
        return {
            menu: [
                {
                    name: "选车",
                    class: "foo-left",
                    link: "/",
                },
                {
                    name: "我的",
                    class: "foo-right",
                    link: "/user",
                },
            ],

            show: false,
            show1: false,
            show2: false,
            active: 0,
            detail: [],
            process_url: "",
            user_notice: "",
            store_list: [],
            install_data: [],
            staging_data: [],
            staging_index: 0,
            selectId: "", // 选中门店id
            form: {
                contact: "",
                contact_phone: "",
                store_id: "",
                staging_id: "",
                install_ids: [],
            },

            placeholder: {
                contact: "请输入姓名",
                contact_phone: "请输入11位手机号",
                store_id: "请选择门店",
            },
            new_car_id: "",
            store_title: "",
        };
    },

    async created() {
        const id = this.$route.query && this.$route.query.id;
        this.new_car_id = id;
        this.getDatail(id);
    },

    methods: {
        next() {
            this.show = true;
            this.show2 = true;
        },
        mendian() {
            this.show1 = true;
            this.show = false;
            this.show2 = true;
        },
        fenqibuy() {
            this.$router.push({ path: "/fenqi?id=" + this.new_car_id });
        },
        close() {
            this.show = false;
            this.show2 = false;
        },
        //获取数据
        getDatail(val) {
            httpDetail({ id: val }).then((res) => {
                if (res.code === 20000) {
                    this.detail = res.data.detail;
                    this.process_url = res.data.process_url;
                    this.user_notice = res.data.user_notice;
                    this.store_list = res.data.store_list;
                    this.staging_data = res.data.staging_data;

                    this.install_data = res.data.install_data.map((item) => {
                        item.checked = false;
                        return item;
                    });
                    let shareUrl = window.location.href;
                    // if(shareUrl.indexOf('from') != -1){
                    //   shareUrl = window.location.href.split("?")[0];
                    // }
                    // window.console.log(url + 'favicon.png');
                    console.log(res,11111);
                    let img = window.location.href.split("#")[0] + 'd.jpg';
                    let shareTitle = '链上汽车';
                    let shareDesc = res.data.share ? res.data.share.share_title : '';
                    console.log(shareDesc,'分享title')
                    let shareImg =  img;
                    // let shareImg = img;
                    console.log(shareImg,'图片111')
                    console.log(img,'本地111')
                    ShareImpl({
                      shareTitle,
                      shareDesc,
                      shareUrl:  location.origin + location.pathname + "?share=true/" + location.hash,
                      shareImg
                    });
                }
            });
        },

        stagingIndex(index, id) {
            this.staging_index = index;
            this.form.staging_id = id;
        },

        check_store(val) {
            this.form.store_id = val.id;
            this.store_title = val.title;
            this.selectId = val.id;
        },

        check_install(item) {
            item.checked = !item.checked;
            this.install_data = this.install_data;
        },

        check_show1() {
            this.show = true;
            this.show1 = false;
        },

        submitForm() {
            //处理选包装
            let install_ids = [];
            this.install_data.map((item) => {
                if (item.checked) {
                    install_ids.push(item.id);
                }
            });
            this.form.install_ids = install_ids;

            if (this.form.contact == "") {
                this.toast({ message: this.placeholder.contact });
                return false;
            }
            if (this.form.contact_phone == "") {
                this.toast({ message: this.placeholder.contact_phone });
                return false;
            }
            if (this.form.store_id == "") {
                this.toast({ message: this.placeholder.store_id });
                return false;
            }

            let data = {
                new_car_id: this.new_car_id,
                buy_type: 1,
                contact: this.form.contact,
                contact_phone: this.form.contact_phone,
                store_id: this.form.store_id,
                install_ids: this.form.install_ids,
                staging_id: this.form.staging_id,
            };

            orderSave(data).then((res) => {
                if (res.code === 20000) {
                    console.log(this.new_car_id, "newid");
                    this.show = false;
                    this.show2 = false;
                    this.toast({ message: res.message });
                    setTimeout(function () {
                        //跳转到订单页
                        window.location.href =
                            res.data.data + "/mobile/index/index#/order";
                        //" http://mlp.milianauto.com/mobile/index/index#/order";
                    }, 500);
                }
            });
        },
    },
};
</script>
<style lang="stylus" scoped>
#fenqi {
    .fenqibox {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #F5F5F5;

        .buy1-box {
            background: #ffffff;
            padding: 6px 12px;
            display: flex;
            margin-bottom: 12px;
            z-index: 100;

            .imgbox {
                background: no-repeat left center / 128px auto;
                width: 128px;
                height: 88px;
                border-radius: 6px;
            }

            .buy1-ibox {
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: center;
                padding-left: 12px;

                .ibox-p {
                    font-size: 16px;
                    color: #333333;
                    font-weight: bold;
                    line-height: 22px;
                    text-align: justify;
                    margin-bottom: 7px;
                }

                .ibox-s {
                    line-height: 22px;

                    .num {
                        font-size: 18px;
                        color: #F23030;
                    }

                    .wan {
                        font-size: 12px;
                        color: #F230 3;
                    }
                }
            }
        }

        .fen-plan {
            margin-bottom: 12px;
            background: #ffffff;

            .fen-title {
                font-size: 18px;
                color: #333333;
                line-height: 28px;
                margin-left: 12px;
                padding-top: 15px;
            }

            .title {
                font-size: 18px;
                color: #333333;
                line-height: 28px;
            }

            .buy2-box {
                padding: 15px 12px;
                border-bottom: 1px solid #F5F5F5;

                .plan-box {
                    display: flex;

                    .plan {
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 14px;
                        height: 36px;
                        line-height: 36px;
                        background: #F5F5F5;
                        margin-right: 13px;
                        border-radius: 3px;
                    }

                    .active {
                        color: #ffffff;
                        background: #4375f2;

                        .name {
                            color: #fff;
                        }

                        &:after {
                            content: '';
                            background: url('../../assets/mine/yuan2.png') no-repeat center center / 17px 17px;
                            width: 17px;
                            height: 17px;
                            margin-left: 8px;
                        }
                    }

                    .plan:last-child {
                        margin-right: 0px;
                    }
                }
            }

            .buy2-money {
                padding: 0 12px;

                .monny-s {
                    padding: 13px 0 14px;
                    display: flex;
                    justify-content: space-between;
                    font-size: 14px;
                    line-height: 24px;
                    color: #666666;
                    border-bottom: 1px solid #F5F5F5;
                }

                .quan-l {
                    padding-left: 27px;
                }

                .quan {
                    background: url('../../assets/mine/yuan1.png') no-repeat left center / 17px 17px;
                }

                .active {
                    background: url('../../assets/mine/yuan2.png') no-repeat left center / 17px 17px;
                }
            }
        }

        .submit {
            margin: 16px 12px;
            height: 44px;
            background: #4375F2;
            border-radius: 22px;
            line-height: 44px;
            display: flex;
            justify-content: center;
            font-size: 16px;
            color: #ffffff;
        }
    }

    .buy-box {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 6px 12px 20px 12px;
        background: #ffffff;
        box-shadow: 0px -5px 15px 0px rgba(153, 153, 153, 0.1);
        border-radius: 10px 10px 0px 0px;
        z-index: 100;
    }

    .close {
        // width 25px
        height: 25px;
        background: url('../../assets/mine/close.png') no-repeat right center / 16px 16px;
    }

    .buy-i {
        display: flex;
        margin-bottom: 20px;

        .imgbox {
            background: no-repeat left center / 128px auto;
            width: 128px;
            height: 88px;
        }

        .buy-ibox {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            padding-left: 12px;

            .ibox-p {
                font-size: 16px;
                color: #333333;
                font-weight: bold;
                line-height: 22px;
                text-align: justify;
                margin-bottom: 7px;
            }

            .ibox-s {
                line-height: 22px;

                .num {
                    font-size: 18px;
                    color: #F23030;
                }

                .wan {
                    font-size: 12px;
                    color: #F23030;
                }
            }
        }
    }

    .form-box {
        .form-box-item {
            font-size: 16px;
            color: #333333;
            line-height: 26px;
            padding: 10px 0px;
            border-bottom: 1px solid #F5F5F5;
            display: flex;

            label {
                display: block;
                width: 30%;
            }

            .form-box-in {
                flex: 1;
            }

            input {
                width: 100%;
                color: #999999;
            }
        }

        .righticon {
            background: url('../../assets/mine/righticon.png') no-repeat right center / 8px 10px;
        }

        .for-box1 {
            font-size: 16px;
            color: #333333;
            line-height: 26px;
            padding: 10px 0px;
            border-bottom: 1px solid #F5F5F5;
            background: url('../../assets/mine/righticon.png') no-repeat right center / 6px 10px;

            .in3 {
                margin-left: 42px;
            }
        }
    }

    .mendian-box {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 50%;
        background: #fff;
        box-shadow: 0px -5px 15px 0px rgba(153, 153, 153, 0.1);
        border-radius: 10px 10px 0px 0px;
        padding-bottom: 20px;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        flex-direction: column;
        z-index: 100;

        .title {
            padding: 15px;
            display: -webkit-box;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            font-weight: normal;
            font-size: 18px;
            color: #333;
            line-height: 28px;
            border-bottom: 1px solid #f5f5f5;
        }

        .mendian-list {
            -webkit-box-flex: 1;
            -webkit-flex: 1;
            flex: 1;
            overflow-y: auto;
        }
    }

    // .submit {
    // margin: 0 12px;
    // height: 44px;
    // background: #e6e6e6;
    // border-radius: 22px;
    // line-height: 44px;
    // display: -webkit-box;
    // display: -webkit-flex;
    // display: flex;
    // -webkit-box-pack: center;
    // -webkit-justify-content: center;
    // justify-content: center;
    // margin-top: 6px;
    // font-size: 16px;
    // color: #fff;
    // }
    // }
    .shade {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: #000;
        opacity: 0.6;
        z-index: 3;
    }
}
</style>